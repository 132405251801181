import React from "react"

const Sections = () => {
  return (
    <aside className="flex flex-col w-1/4 card-info">
      <h2>Quick Links</h2>
      <ul>
        <li>CUIT Data Platform</li>
        <li>Academic Commons</li>
        <li>READI</li>
        <li>RDS</li>
        <li>DMPTool</li>
      </ul>
    </aside>
  )
}

export default Sections
