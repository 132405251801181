import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Accordion, Card } from "react-bootstrap"
import { MDXRenderer } from "gatsby-plugin-mdx"

export default function Section({ documents }) {

  console.log(documents);

  return (
    <Accordion>
      {documents.map(({ node }, i) => (
        <Card key={i}>
          <Accordion.Toggle as={Card.Header} variant="link" eventKey={node.name}>
            {node.childMdx.frontmatter.title}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={node.name}>
            <Card.Body>
              <MDXRenderer>{node.childMdx.body}</MDXRenderer>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      ))}
    </Accordion>
  )

}
