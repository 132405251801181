import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Accordion, Card } from "react-bootstrap"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Section from "./section";

const Sections = () => {
  const sections = [
    { 
      name: "Brainstorm Ideas",
      path: "brainstorm",
    },
    { 
      name: "Capture Data",
      path: "capture",
    },
    { 
      name: "Manage Data",
      path: "manage",
    },
    { 
      name: "Process and Analyze Data",
      path: "process-and-analyze",
    },
    { 
      name: "Publish and Share Data",
      path: "publish-and-share",
    },
  ];

  const data = useStaticQuery(graphql`
    query SectionsQuery {
      sections: allFile(
        filter: { sourceInstanceName: { eq: "sections" } }
        sort: { fields: childMdx___frontmatter___order }
      ) {
        edges {
          node {
            absolutePath
            name
            childMdx {
              frontmatter {
                title
              }
              body
            }
          }
        }
      }
    } 
  `);

  console.log(data);

  return (
    <>
    {
      sections.map(section => {
        const documents = data.sections.edges.filter(doc => doc.node.absolutePath.includes(section.path));
        return (
        <section className="flex flex-col py-6 card-info" key={section.path}>
          <h2 className="pb-2">{section.name}</h2>
          <Section documents={documents} />
        </section>
        )
      })
    }
    </>
  )
}

export default Sections
