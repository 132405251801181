import React, { useState } from "react"
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import SEO from "../components/seo"
import Layout from "../components/layout"
import Article from "../components/article"
import Sections from "../components/sections"
import Aside from "../components/aside"

const Index = () => {

  const [openAccordion] = useState("default key");

  return (
    <Layout>
      <SEO title="Home" />
      <Article>
        <p className="p-6">Introductory paragraph.</p>
        <Sections />
      </Article>
      <Aside />
    </Layout>
  );
}

export default Index

